import './scss/main.scss';
import './dom.js';
import { createApp } from 'vue';
import GrAlert from './components/GrAlert.vue';
import GrIcon from './components/GrIcon.vue';
import GrHamburger from './components/GrHambuger.vue';
import GrScrollTop from './components/GrScrollTop.vue';
import GrInput from './components/GrInput.vue';
import GrButton from './components/GrButton.vue';

createApp({components: {GrAlert, GrIcon, GrHamburger, GrScrollTop, GrInput, GrButton}}).mount('#app');
