import URI from 'urijs';
import Lazyload from 'lazyload';

const lazyloadImages = () => {
    new Lazyload();
};

const replaceURLsByLinks = () => {
    const replaceURLByLink = html => URI.withinString(
        html,
        url => `<a href="${url}" class="gr-link" target="_blank">${URI(url).domain()}</a>`
    );

    document.querySelectorAll('.gr-autolink').forEach(element => {
        element.innerHTML = replaceURLByLink(element.innerHTML);
    });
};

document.addEventListener('DOMContentLoaded', () => {
    lazyloadImages();
    replaceURLsByLinks();
});
