<script setup>
import GrIcon from './GrIcon.vue';
import {computed} from 'vue';

const props = defineProps({
    type: {
        type: String,
        validator(value) { return ['info', 'success', 'error'].includes(value);}
    }
});

const icon = computed(() => {
    if (props.type === 'error') {
        return 'error';
    }

    if (props.type === 'success') {
        return 'success';
    }

    return 'info';
});
</script>

<template>
    <div class="gr-alert" :class="'gr-alert--' + type">
        <div class="gr-alert__icon"><gr-icon :icon="icon" size="lg" /></div>
        <p class="gr-alert__message"><slot/></p>
    </div>
</template>

<style lang="scss">
@use '../scss/config';

.gr-alert {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    font-weight: bold;
    color: config.$white;

    .gr-link {
        color: config.$white;
    }

    &--info {
        background: config.$info;
    }

    &--success {
        background: config.$success;
    }

    &--error {
        background: config.$error;
    }

    &__icon {
        padding: .5rem;
    }

    &__message {
        flex: 1;
    }
}
</style>
