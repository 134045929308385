<script setup>
import { ref } from 'vue';

const showHamburgerMenu = ref(false)
</script>

<template>
    <div class="gr-hamburger" :class="{'gr-hamburger--opened': showHamburgerMenu}" @click="showHamburgerMenu = !showHamburgerMenu">
        <div class="gr-hamburger__bar gr-hamburger__bar--1" />
        <div class="gr-hamburger__bar gr-hamburger__bar--2" />
        <div class="gr-hamburger__bar gr-hamburger__bar--3" />

        <Transition name="slideY">
            <div v-if="showHamburgerMenu" class="gr-hamburger__menu">
                <div class="gr-hamburger__menu__inner">
                    <slot />
                </div>
            </div>
        </Transition>
    </div>
</template>

<style lang="scss">
@use '../scss/config';

.gr-hamburger {
    & {
        cursor: pointer;

        display: none;
        @include config.media-max("desktop"){
            display: block;
        }
    }

    &__bar {
        position: relative;
        z-index: 2;
        width: 33px;
        height: 1px;
        margin: 8px 0;
        background: #000000;

        transform-origin: 0 0;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;

        &--1 {
            transform-origin: 0 0;
        }

        &--2 {
            transform-origin: 0 100%;
        }
    }

    &--opened {
        .gr-hamburger__bar {
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);

            &--2 {
                transform: rotate(-45deg) translate(-10px, 6px);
            }

            &--3 {
                opacity: 0;
            }
        }
    }

    &__menu {
        position: absolute;
        top: 4rem;
        left: 0;
        width: 100vw;
        background: rgba(config.$white, .96);
        z-index: 1;

        &__inner {
            transition: all .5s ease-in-out;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            .gr-hamburger__link {
                text-transform: uppercase;
                display: block;
                font-size: 1.25rem;
                padding: .75rem;

                &--active {
                    color: config.$red
                }
            }
        }

        &__languages {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 .75rem;
            width: 100%;
            border-top: 1px solid config.$grey;
        }
    }
}
</style>
