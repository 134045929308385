<script setup>
import throttle from 'lodash-es/throttle';
import GrIcon from './GrIcon.vue';
import {ref} from "vue";

const visible = ref(false);

const smoothScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
        window.requestAnimationFrame(smoothScroll);
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)));
    }
};

const shouldDisplayScrollTop = () => {
    return window.pageYOffset > 260;
};

const displayScrollTop = throttle(function () {
    visible.value = shouldDisplayScrollTop();
}, 300);

window.addEventListener('scroll', displayScrollTop, {passive: true});
</script>

<template>
    <transition name="fade">
        <div v-show="visible" class="gr-scroll-top" @click="smoothScroll">
            <gr-icon icon="arrow-up"/>
        </div>
    </transition>
</template>

<style lang="scss">
@use '../scss/config';

.gr-scroll-top {
    border: 2px solid config.$red;
    border-radius: 3rem;
    position: fixed;
    padding: 1.5rem;
    bottom: 1rem;
    z-index: 10;
    right: 1rem;
    background: white;
    color: config.$red;
    height: 1rem;
    width: 1rem;
    text-align: center;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .045);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: config.$black;
        border-color: config.$black;
    }
}
</style>
