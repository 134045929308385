<script setup>
import {ref, onMounted, computed} from 'vue';
import autosize from 'autosize';

const props = defineProps({
    name: {type: String, required: true},
    label: {type: String, required: true},
    type: {type: String, default: 'text'},
    hint: {type: String, default: null}
});

const textarea = ref(null);
const isHoneypot = computed(() => props.name === 'gdsfldfdsf');

onMounted(() => {
    if (textarea) {
        autosize(textarea.value);
    }
});
</script>

<template>
    <div class="gr-input" :class="{'gr-input--hp': isHoneypot}">
        <input class="gr-input__input"
               :name="name"
               :required="!isHoneypot"
               :class="'gr-input__input--' + type"
               :type="type"
               :placeholder="label"
               v-if="type !== 'textarea'" />
        <textarea ref="textarea"
                  :name="name"
                  minlength="50"
                  :required="!isHoneypot"
                  class="gr-input__input gr-input__input--textarea"
                  @focus="$event.target.select()"
                  :placeholder="label"
                  v-else></textarea>

        <div class="gr-input__hint" v-if="hint">{{ hint }}</div>
    </div>
</template>

<style lang="scss">
@use '../scss/config';
@use "sass:color";

.gr-input {
    &--hp {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

    & {
        margin: 1rem;
    }

    &__input {
        border: 2px solid config.$black;
        font-family: 'Playfair Display', sans-serif;
        font-size: 1.1rem;
        padding: .5rem;
        border-radius: 0;
        width: 100%;

        &:focus {
            outline: none;
            border-color: color.adjust(config.$red, $lightness: -30%);
        }

        &:not(:placeholder-shown):invalid {
            border-color: config.$error;
        }

        &--text, &--email {
            height: 40px;
        }

        &--textarea {
            min-height: 80px;
        }
    }

    &__hint {
        font-size: .8rem;
    }
}
</style>
