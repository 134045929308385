<script setup>
</script>

<template>
    <button type="submit" class="gr-button"><slot/></button>
</template>

<style lang="scss">
@use '../scss/config';

.gr-button {
    border: none;
    background: config.$red;
    color: config.$white;
    padding: .5rem 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
      background-color: rgba(config.$red, .8);
    }
}
</style>
